<template>
    <div>
        <header-portrate />
        <exports-print ref="exportbar" />
    <div>
        <bread-crumbs :items="items"></bread-crumbs>
        <tabs-comp :tabs="tabs" />
        <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:15px !important;`">
            <v-col cols="12" md="10" sm="12" :style="`direction:`+lang.dir+`;text-align:`+lang.algin">
                <form autocomplete="off">
                    <v-row>
                        <v-col cols="12" md="2" sm="12">
                            <label>{{lang.start_date}}</label>
                            <b-input-group>
                                <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#c3d9ff;" v-model="sd.day" :options="daysList"/>
                                <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="sd.month" :options="monthsList" />
                                <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#c3d9ff;" v-model="sd.year" :options="yearsList" />
                            </b-input-group>
                        </v-col>
                        <v-col cols="12" md="2" sm="12">
                            <label>{{lang.end_date}}</label>
                            <b-input-group>
                                <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#c3d9ff;" v-model="ed.day" :options="daysList" />
                                <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="ed.month" :options="monthsList" />
                                <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#c3d9ff;" v-model="ed.year" :options="yearsList" />
                            </b-input-group>
                        </v-col>
                        <v-col cols="12" md="2" sm="12">
                            <label>{{lang.mobile}}</label>
                            <b-input-group>
                                <b-form-input class="inborder" v-model="mobile" style="background:#c3d9ff;" />
                            </b-input-group>
                        </v-col>
                        <v-col cols="12" md="2" sm="12">
                            <label>{{lang.invoiceno}}</label>
                            <b-input-group>
                                <b-form-input class="inborder" v-model="invoiceno" style="background:#c3d9ff;" />
                            </b-input-group>
                        </v-col>
                        <v-col cols="12" md="2" sm="12">
                            <label>{{lang.vendor_name}}</label>
                            <b-input-group>
                                <b-form-input class="inborder" v-model="vendor_name" style="background:#c3d9ff;" />
                            </b-input-group>
                        </v-col>
                        <!-- <v-col cols="12" md="2" sm="12">
                            <label>{{lang.expeneses_type}}</label>
                            <b-input-group>
                                <b-form-select class="selborder" :options="expenesesTypes" v-model="expeneses_type" style="background:#c3d9ff;" />
                            </b-input-group>
                        </v-col> -->
                        <v-col cols="12" md="2" sm="12">
                            <label></label>
                            <b-input-group >
                                <b-button variant="light" style="padding-top:2px !important;padding-bottom:2px !important;background:blue;color:#fff;width:75px;"  @click="getInvoice()">{{lang.search}}</b-button>
                            </b-input-group>
                        </v-col>
                    </v-row>
                </form>
            </v-col>
            <v-col cols="12" md="2" sm="12" :class="`pt-7 text-`+lang.lalgin" style="padding-inline:30px;">
                
                <b-button
                    v-b-toggle.add_genexpense
                    class="btn-sm btn "
                    style="margin-top:11px;width:150px;background:transparent;border:none"
                    id="multiply-button" 
                    @click="addExp()"
                    >
                    <div style="width:150px;" :class="`multiply-button-content-`+lang.lalgin">{{lang.add_general_expense}}</div>
                    
                </b-button>

            </v-col>
        
            <v-col cols="12" md="12" sm="12" :style="`padding-inline-end:40px !important;direction:`+lang.dir+`;margin-top:3px;text-align:`+lang.align">
                <v-data-table
                    :headers="headers"
                    :items="purchrows"
                    :search="search"
                    :items-per-page="16"
                    class="elevation-1"
                    hide-default-footer
                >
                <template v-slot:item="row">
                <tr>
                    <td class="text-center" style="width:70px">{{ row.item.bill_number }}</td>
                    <td class="text-center" nowrap>{{ row.item.vendor }}</td>
                    <td class="text-center" nowrap v-if="lang.langname == 'ar'">{{row.item._expenses_type_ar}}</td>
                    <td class="text-center" nowrap v-else-if="lang.langname == 'en'">{{row.item._expenses_type_en}}</td>
                    <td class="text-center" style="width:100px">{{ row.item.bill_date }}</td>
                    <td class="text-center" style="background:lightgreen;width:100px">{{ row.item.total }}</td>
                    <td class="text-center" style="background:lightgreen;width:100px">{{ row.item.vat }}</td>
                    <td class="text-center" style="width:100px">{{ row.item.ftotal }}</td>
                    <td class="text-center" style="width:70px;background:red;border-top:2px solid #fff !important;">
                    <v-btn style="font-size:0.2em;background:red;width:70px;color:#FFF;height:25px !important;margin-right:5px;" @click="delItem(row.item)">{{lang.delete}}</v-btn>
                    </td>
                    <td class="text-center" style="width:70px;background:blue;border-top:2px solid #fff !important;">
                        <v-btn style="font-size:0.2em;background:blue;width:70px;color:#FFF;height:25px !important;" @click="ViewItem(row.item)"  v-b-toggle.viewPurch>{{lang.view}}</v-btn>
                    </td>
                </tr>
                </template>
                <template slot="body.append" v-if="shoTotals">
                    <tr>
                        <th class="backEmpty"> </th>
                        <th class="backEmpty"> </th>
                        <th class="backEmpty"> </th>
                        <th class="backBlack" style="text-align:center;font-size:1.2em;font-weight:bold">{{lang.totals}}</th>
                        <th class="backRed" style="text-align:center;font-size:1.2em;font-weight:bold">{{totals.total}}</th>
                        <th class="backGreen" style="text-align:center;font-size:1.2em;font-weight:bold">{{totals.vat}}</th>
                        <th class="backBlack" style="text-align:center;font-size:1.2em;font-weight:bold">{{totals.ftotal}}</th>
                        <th class="backEmpty"></th>
                    </tr>
                </template>
                </v-data-table>
                <v-container style="direction:rtl">
                    <div class="text-center pt-2" style="direction:rtl">
                    <v-pagination
                        v-model="page"
                        value="10"
                        :length="pageCount"
                        style="direction:rtl"
                        color="#000"
                        @input="getNext(page)"
                    ></v-pagination>
                    </div>
                </v-container>
            </v-col>
        </v-row>
        <expenses-gen ref="addExp" />
    </div>
        <Footer />
        <div class="loadingPage" v-if="$store.state.showLoading">
            <v-progress-circular
                :width="3"
                color="green"
                indeterminate
            ></v-progress-circular>
        </div>
      <ViewPurchase ref="viewMenow" />
    </div>
</template>

<script>
import breadCrumbs from '@/components/breadCrumbs.vue'
import TabsComp from '@/components/tabsComp.vue'
import axios from 'axios';
import ExpensesGen from '@/components/expenses-gen.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
import ViewPurchase from '@/components/ViewPurchase.vue';
import ExportsPrint from '@/components/exports-print.vue';
export default {
    components: { breadCrumbs, TabsComp, ExpensesGen, HeaderPortrate,Footer,ViewPurchase,ExportsPrint },
    data() {
        return {
            allres:0,
            totals: {
                total: 0, vat: 0, ftotal:0
            },
            cpage:0,
            id:0,
            active_tab:2,
            search: '',
            purchrows:[],
            page: 1,
            pageCount:0,
            expeneses_type:'',
            expenesesTypes: [],
            mobile: '',
            vendor_name: '',
            // sdate: '',
            // edate: '',
            invoiceno: '',
            sd: {
                day: 1,
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            },
            ed: {
                day: (new Date()).getDate() ,
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            }
        }
    },
    created() {
        this.getStartDate();
        this.getInvoice();
        this.getExpType();
    },
    methods: {
        delItem(item)
        {
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','delPurchase');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[id]',item.id);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.getInvoice();
            });
        },
        getStartDate(){
            let d = (new Date()).getDate();
            let m = (new Date()).getMonth() + 1;
            let y = (new Date()).getFullYear();
            if(parseInt(d) < 30){
            d = parseInt(30) - parseInt(d);
            if(parseInt(m) == 1){
                m = 12
                y = parseInt(y) - 1
            }
            else if(parseInt(m) > 1){
                m = parseInt(m) - 1;
            }
            }else if(parseInt(d) == 30){
            d = 1;
            }else{
            d = parseInt(d) - parseInt(30);
            }
            this.sd.day = 1;
            this.sd.month = 1;
            this.sd.year = y;
        },
        getExpType(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getExpType");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[type]',this.getExpense);
            post.append('data[expenses]',this.$store.getters['state'].licenseType.onlySalesPurchase ? 0 : 1);
            let myar = [];
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                if(res.results.data.length != 0){
                    for(let i=0;i<res.results.data.length;i++){
                        myar.push(
                            {
                                text: (this.lang.langname == "ar") ? res.results.data[i].namear : res.results.data[i].namear, 
                                value: res.results.data[i].classid,
                            }
                        );
                    }
                }
                this.expenesesTypes = myar;
                // this.expeneses_type = res.results.data[0].classid;
            })
        },
        preparedata(){ 
            let exdata = {
                list: "expensesListList",
                title: this.lang.expenses,
                data: [],
            }
            for(let i=0;i<this.purchrows.length;i++){
                exdata.data.push(this.purchrows[i]);
            }
            // // console.log(exdata);
            this.$refs.exportbar.exportdata = exdata;
            this.$refs.exportbar.sdate = this.sdate;
            this.$refs.exportbar.edate = this.sdate;
            
            this.$refs.exportbar.exporttitle = "";
            this.$refs.exportbar.palte_number = '';
            this.$refs.exportbar.contact_number = '';
        },
        ViewItem(item){
          this.$refs.viewMenow.billid = item.id;
          this.$refs.viewMenow.getBill();

          //this.$router.push({path: '/view-purchase/'+item.id})
        },
        addExp(){
            this.$refs.addExp.getExpense = 1;
            this.$refs.addExp.getExpType();
        },
        getNext(page){
            this.cpage = parseInt(page) - 1;
            this.getInvoice();
        },
        getInvoice(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getPurchase');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[id]',this.id);
            post.append('data[sdate]',this.sdate);
            post.append('data[edate]',this.edate);
            post.append('data[type]',2);
            post.append('data[cpage]',this.cpage);
            post.append('data[invoiceno]',this.invoiceno);
            post.append('data[mobile]',this.mobile);
            post.append('data[vendor_name]',this.vendor_name);
            post.append('data[expeneses_type]',this.expeneses_type);
            this.$store.state.showLoading = true
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                this.$store.state.showLoading = false;
                const res = response.data;
                // // console.log(res);
                // this.pageCount = parseInt(response.data.allres) / 15
                // if(parseFloat(this.pageCount) != parseInt(this.pageCount)){
                //     this.pageCount = parseInt(this.pageCount) + 1;
                // }
                this.allres = res.results.allres
                this.pageCount = parseInt(parseInt(res.results.allres) / 15) + 1
                this.page = parseInt(this.cpage) + 1
                this.purchrows = res.results.data;
                this.totals = res.results.totals
            });
        },
        ExportIt(type){
            let url = this.$store.state.SAMCOTEC.e_path+'?';
            if(type != 'e'){
                let url = this.$store.state.SAMCOTEC.pd_path+'?inrecepit';
            }
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('export','inrecepit');
            post.append('type',1);
            post.append('sdate','');
            post.append('edate','');
            axios.post(
                url, post
            ).then((res) => {
                var rest = res.data;
                window.open('../api' + rest.url,'_blank')
            })
        },
        exportE(type){
            let url = '';
            if(type == 'e'){
                url = this.$store.state.SAMCOTEC.e_path;
            }else{
                url = this.$store.state.SAMCOTEC.pd_path;
            }
            const myhead = this.headers;
            const mydata = this.purchrows;
            const reportname = 'فواتير المشتريات';
            const period = this.sdate + ' - ' + this.edate;
            const head = []
            const keys = []
            
            if(myhead.length > 0){
                for(let i = 0;i < myhead.length;i++){
                    head.push(myhead[i].text);
                    if(myhead[i].text != 'الاجراء')
                        keys.push(myhead[i].value);
                }
            }
            
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("export" , 'inrecepit');
            post.append("keys",keys);
            post.append("header",head);
            if(mydata.length > 0){
                for(let i = 0;i< mydata.length;i++){
                    for(const [key, value] of Object.entries(mydata[i])){
                       post.append('data['+i+']['+key+']',value);
                    }
                }
            }
            post.append("reportname",reportname);
            post.append("period",period);
            axios.post( url , post)
            .then((res) => {
                window.open('../api/'+res.data.url,'_blank');
            })
        },
        getExpenses(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , "getExpenseSummary");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[sdate]",this.sdate);
            post.append("data[edate]",this.edate);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    ///this.exprows = res.results.data.results;
                    
                    // // console.log(res);
                    this.getExpenses();
                }
            )
        },
        getExpensType(id){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , "getExpensType");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[id]",id);

            axios.post( this.$SAMCOTEC.r_path, post)
            .then((res) => {
                // // console.log("sdfsdf",res.data.results.data.ar);
                if(this.lang.langname == 'ar') return res.data.results.data.ar;
                else if(this.lang.langname == 'en') return res.data.results.data.en;
            })

        }
    },
    computed: {
        shoTotals: function (){
            let t = false;
            if(this.pageCount == this.page){
                t = true;
            }
            t = true;
            return t;
        },
        cpages: function(){
            // alert(parseFloat(this.page) * 15);
            // alert(this.pageCount);
            if(parseFloat(this.page) * 15 >= this.allres){
            return true
            }
            return false;
        },
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        sdate: function() {
            return (new Date(this.sd.year,this.sd.month-1, this.sd.day+1)).toISOString().substring(0,10);
        },
        edate: function() {
            return (new Date(this.ed.year,this.ed.month-1, this.ed.day+1)).toISOString().substring(0,10);
        },
        yearsList: function(){
            const start_year = this.$store.state.licenseType.startYear;
            let t = [{text: this.lang.year, value: -1}];
            for(let i=0;i<100;i++){
            if(parseInt(start_year) + i <= (new Date()).getFullYear()){
                t.push({text: start_year + i, value: start_year+i})
            }
            
            }
            return t
        },
        daysList: function(){
            return [
            {text: this.lang.day, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
            {text: 13, value: 13},
            {text: 14, value: 14},
            {text: 15, value: 15},
            {text: 16, value: 16},
            {text: 17, value: 17},
            {text: 18, value: 18},
            {text: 19, value: 19},
            {text: 20, value: 20},
            {text: 21, value: 21},
            {text: 22, value: 22},
            {text: 23, value: 23},
            {text: 24, value: 24},
            {text: 25, value: 25},
            {text: 26, value: 26},
            {text: 27, value: 27},
            {text: 28, value: 28},
            {text: 29, value: 29},
            {text: 30, value: 30},
            {text: 31, value: 31},
            ]
        },
        monthsList: function(){
            return [
            {text: this.lang.chose_month, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
            ]
        },
        headers: function() {
            return [
                {
                    text: this.lang.invoiceno,
                    align: 'center',
                    sortable: true,
                    value: 'bill_number',
                    class:'backBlack',printRemove: 0
                },
                {
                    text: this.lang.vendor,
                    align: 'center',
                    sortable: false,
                    value: 'vendor',
                    class:'backBlack',printRemove: 0
                },
                {
                    text: this.lang.expeneses_type,
                    align: 'center',
                    sortable: false,
                    value: 'type',
                    class:'backBlack',printRemove: 0
                },
                {
                    text: this.lang.inv_date,
                    align: 'center',
                    sortable: false,
                    value: 'bill_date',
                    class:'backBlack',printRemove: 0
                },
                {
                    text: this.lang.total,
                    align: 'center',
                    sortable: false,
                    value: 'total',
                    class:'backRed',printRemove: 0
                },
                {
                    text: this.lang.vat,
                    align: 'center',
                    sortable: false,
                    value: 'vat',
                    class:'backGreen',printRemove: 0
                },
                {
                    text: this.lang.ftotal,
                    align: 'center',
                    sortable: false,
                    value: 'ftotal',
                    class:'backBlack',printRemove: 0
                },
                {
                    text: this.lang.delete,
                    align: 'center',
                    sortable: false,
                    class:'backBlack',
                    colspan: '2',printRemove: 0
                },
                {
                    text: this.lang.action,
                    align: 'center',
                    sortable: false,
                    class:'backBlack',
                    colspan: '2',printRemove: 1
                }
            ]
        },
        tabs: function(){
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        },
        items: function() {
            return {
                    text: this.lang.general_expenses,
                    disabled: true,
                    to: '/expenses/summary',
                }
        },
        ptotals: function(){
            let x = 0;
            for(let i = 0;i < this.purchrows.length;i++){
                x = parseFloat(this.purchrows[i].total) + parseFloat(x);
            }
            return this.$RoundNum(x);
        },
        pvat: function(){
            let x = 0;
            for(let i = 0;i < this.purchrows.length;i++){
                x = parseFloat(this.purchrows[i].vat) + parseFloat(x);
            }
            return this.$RoundNum(x);
        },
        pftotals: function(){
            let x = 0;
            for(let i = 0;i < this.purchrows.length;i++){
                x = parseFloat(this.purchrows[i].ftotal) + parseFloat(x);
            }
            return this.$RoundNum(x);
        },
    },
}
</script>
<style>
.loadingPage{
  position: fixed;
  left:0;
  right:0;
  top:0;
  bottom: 0;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  background:#ffffff00;
  z-index: 100000;
}
</style>